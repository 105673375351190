@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
/* base styles */
* {
	font-family: "Quicksand";
	color: #fff;
	margin: 0 auto;
	
}
.navbar {
	padding-top: 10px;
	padding-left: 5px;
	padding-right: 5px;
	padding-bottom: 20px;
	display: flex;
	align-items: center;
	margin: 0 auto;
	background-color: #222;
	white-space: nowrap;
}
.navbar h1 {
	color: #FF8C00;
}
.navbar .links {
	margin-left: auto;
}
.navbar a {
	text-decoration: none;
	padding: 10px;
	color: #FF8C00;
}
.navbar a:hover {
	color: #222;
	background-color: #FF8C00;
}
.content {
	padding: 20px;
	background-image: linear-gradient(45deg, #d64363, #ac288f, #1f8ab1, #1b9b7f);
	background-size: 400%, 400%;
	height: 600px;
	position: relative;
	-webkit-animation: change 20s ease-in-out infinite;
	        animation: change 20s ease-in-out infinite;
}
.info {
	max-width: 600px;
}
.info p {
	text-align: left;
	border: 1px solid #fff;
	padding: 15px;
}
.experience{
	background-color: #222;
	padding: 20px;
}
img{
	border-radius: 50%;
}
.personal{
	width: 300px;
	height: 300px;
}
.github{
	width: 30px;
	height: 30px;
}
.experience ul{
	max-width: 500px;
	text-align: left;
}
.pdf{
	width: 100vw;
	height: 100vh;
}
.map-container {
	height: 70vh;
	width: 50vw;
	margin-left: 20px;
	margin-top: 20px;
	border: #222 solid 3px;
}
@-webkit-keyframes change {
  0%{
    background-position: 0% 50%;
  }
  50%{
    background-position: 100% 50%;
  }
  100%{
    background-position: 0% 50%;
  }
}
@keyframes change {
  0%{
    background-position: 0% 50%;
  }
  50%{
    background-position: 100% 50%;
  }
  100%{
    background-position: 0% 50%;
  }
}
.box-area {
	position: absolute;
	top: 0;
	left: 0;
	width: 90%;
	height: 100%;
	overflow: hidden;
}
.box-area li {
	position: absolute;
	display: block;
	list-style: none;
	width: 25px;
	height: 25px;
	background: rgba(255, 255, 255, 0.2);
	-webkit-animation: animate 20s linear infinite;
	        animation: animate 20s linear infinite;
	bottom: -150px;
}
.box-area li:nth-child(1) {
	left: 86%;
	width: 80px;
	height: 80px;
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
}
.box-area li:nth-child(2) {
	left: 12%;
	width: 30px;
	height: 30px;
	-webkit-animation-delay: 1.5s;
	        animation-delay: 1.5s;
	-webkit-animation-duration: 10s;
	        animation-duration: 10s;
}
.box-area li:nth-child(3) {
	left: 70%;
	width: 100px;
	height: 100px;
	-webkit-animation-delay: 5.5s;
	        animation-delay: 5.5s;
}
.box-area li:nth-child(4) {
	left: 42%;
	width: 150px;
	height: 150px;
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
	-webkit-animation-duration: 15s;
	        animation-duration: 15s;
}
.box-area li:nth-child(5) {
	left: 65%;
	width: 40px;
	height: 40px;
	-webkit-animation-delay: 0s;
	        animation-delay: 0s;
}
.box-area li:nth-child(6) {
	left: 15%;
	width: 110px;
	height: 110px;
	-webkit-animation-delay: 3.5s;
	        animation-delay: 3.5s;
}
@-webkit-keyframes animate {
	0% {
		-webkit-transform: translateY(0) rotate(0deg);
		        transform: translateY(0) rotate(0deg);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(-600px) rotate(360deg);
		        transform: translateY(-600px) rotate(360deg);
		opacity: 0;
	}
}
@keyframes animate {
	0% {
		-webkit-transform: translateY(0) rotate(0deg);
		        transform: translateY(0) rotate(0deg);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(-600px) rotate(360deg);
		        transform: translateY(-600px) rotate(360deg);
		opacity: 0;
	}
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

